<template>
<s-container class="no-bg">
  <div class="s-container-wrap">
    <a-form-model :model="form"  :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-model-item
        v-for="item in itemList"
        :label="item.label" 
        :key="item.id">
        <a-textarea auto-size v-model="form[item.model]"/>
      </a-form-model-item>
      <a-form-model-item class="s-search-btn-group">
        <a-button type="primary" @click="onSubmit">
          检索
        </a-button>
        <a-button style="margin-left: 10px;">
          清空
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>

</s-container>
<!-- <div class="s-container s-tablesearch">

</div> -->
</template>

<script>
export default {
  name:'TableSearch',
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      itemList:[
        {id:0,label:"申请(专利)号",model:"AN"},
        {id:1,label:"申请日",model:"AD"},
        {id:3,label:"公开(公告)号",model:"PNM"},
        {id:4,label:"公开(公告)日",model:"PD"},
        {id:5,label:"名称，摘要，权利要求书",model:"TIABSTCLM"},
        {id:6,label:"名称,摘要",model:"TIABST"},
        {id:7,label:"摘要",model:"ABST"},
        {id:8,label:"名称",model:"TI"},
        {id:9,label:"权利要求书",model:"CLM"},
        {id:10,label:"说明书",model:"DESCR"},
        {id:11,label:"申请人，当前专利权人",model:"PACAS"},
        {id:12,label:"地址",model:"ADDR"},
        {id:13,label:"申请(专利权)人",model:"PA"},
        {id:14,label:"申请人集合",model:"PATMS"},
        {id:15,label:"发明(设计)人",model:"INN"},
        {id:16,label:"发明人集合",model:"INNTMS"},
        {id:17,label:"有效性",model:"LV"},
        {id:18,label:"当前法律状态",model:"CLS"},
        {id:19,label:"IPC/LOC",model:"SIC"},
        {id:20,label:"主IPC/LOC",model:"PIC"},
        {id:21,label:"CPC",model:"CPC"},
        {id:22,label:"优先权",model:"PR"},
        {id:23,label:"分案原申请号",model:"DAN"},
        {id:24,label:"专利代理机构",model:"AGC"},
        {id:25,label:"代理人",model:"AGT"},
        {id:26,label:"国际公布",model:"IPN"},
        {id:27,label:"国际申请",model:"IAN"},
        {id:28,label:"进入国家日期",model:"DEN"}
     ],
      form:{
        AN:'',//申请号
        AD:'',//申请日
        PNM:'',//公开(公告)号
        PD:'',//公开(公告)日
        TIABSTCLM:'',//名称，摘要，权利要求书
        TIABST:'',//名称,摘要
        TI:'',//名称
        ABST:'',//摘要
        CLM:'',//权利要求书
        DESCR:'',//说明书
        PACAS:'',//申请人，当前专利权人
        ADDR:'',//地址
        PA:'',//申请(专利权)人
        PATMS:'',//申请人集合
        INN:'',//发明(设计)人
        INNTMS:'',//发明人集合
        LV:'',//有效性
        CLS:'',//当前法律状态
        SIC:'',//IPC/LOC
        PIC:'',//主IPC/LOC
        CPC:'',//CPC
        PR:'',//优先权
        DAN:'',//分案原申请号
        AGC:'',//专利代理机构
        AGT:'',//代理人
        IPN:'',//国际公布
        IAN:'',//国际申请
        DEN:''//进入国家日期
      }
    }
  },
  methods: {
    onSubmit(){
      console.log(this.form[0].AN);
    }
  }
}
</script>
<style scoped>
.ant-form-item{
  width: 50%;
  float: left;
  margin-bottom: 5px;
}
</style>>
